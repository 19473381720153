<template>
	<div
		class="min-h-screen brand-neutral-4 font-poppins pb-28 w-full md:w-96 md:mx-auto overflow-hidden flex flex-col relative"
	>
		<slot />
		<MobileMenu v-if="!isScannerOpened" />
	</div>
</template>
<script setup lang="ts">
const qrCodeScanner = useQRCodeScanner()
const { isScannerOpened } = storeToRefs(qrCodeScanner)
const nuxtApp: any = useNuxtApp()
nuxtApp.$addPushNotificationsListener()
</script>
